import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/core/models/paginations/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CandidatureService {


  constructor(private readonly http: HttpClient) { }

  newCandidature(data){
    console.log("🚀 ~ TombolaService ~newCandidature ~ data:", data)
    return this.http.post(
      `${environment.apiV1}condidatures`, data
    );
  }


  uploadFiles( fd) {
    console.log("🚀 ~ file: administartive.service.ts:25 ~ AdministartiveService ~ uploadFiles ~ fd:", fd)
    return this.http.post(`${environment.apiV1}condidatures/uploadCV`, fd)
  }



  getPaginateInscrit():Observable<Pagination<any>>{
    return this.http.get<Pagination<any>>(`${environment.apiV1}condidatures/paginateInscrit`)
  }

  searchInscrit(searchInscritTerm:string):Observable<Pagination<any>>{
    return this.http.get<Pagination<any>>( `${environment.apiV1}condidatures/paginateInscrit?searchInscritTerm=${searchInscritTerm}`)
  }

  funcPaginate(
    link?: string,
    page?: number,
    search?: string
  ): Observable<Pagination<any>> {
    if (page && search == undefined) {
      link = `${environment.apiV1}condidatures/paginateInscrit?page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (page && search) {
      link = `${environment.apiV1}condidatures/paginateInscrit?searchInscritTerm=${search}&page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (search) {
      return this.http.get<Pagination<any>>(`${link}&searchInscritTerm=${search}`);
    }
    return this.http.get<Pagination<any>>(link);
  }
  downloadFileCv(id: number){
    return this.http.get(`${environment.apiV1}condidatures/downloadFileCv/${id}`, {
      responseType: 'blob',
    })
  }

}
