import { Component } from '@angular/core';
import { TombolaService } from '../commercial/tombola/tombola.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-offre',
  templateUrl: './offre.component.html',
  styleUrls: ['./offre.component.scss']
})
export class OffreComponent {
  response = false
  constructor(
    private tombolaService: TombolaService,
    private location: Location

  ) {

  }
  ngOnInit() {
    this.downloadOffre()
  }

  downloadOffre() {
    this.tombolaService.downloadOffre().subscribe({
      next: (res) => {
        this.openFile(res, 'application/pdf');
        this.response = false
        this.location.back();
      },

      error: (error) => {
        this.response = true

        console.log("🚀 ~ OffreComponent ~ this.tombolaService.downloadTarifs ~ error:", error)

      }
    })
  }
  openFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'Offre_service.pdf'; // Spécifie le nom du fichier
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
}
