import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IInscriptionTombola } from 'src/app/core/interfaces/i-inscriptionTombola';
import { Pagination } from 'src/app/core/models/paginations/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TombolaService {

  constructor(private readonly http: HttpClient) { }

  InscrireUserToTombola(data){
    console.log("🚀 ~ TombolaService ~ InscrireUserToTombola ~ data:", data)
    return this.http.post(
      `${environment.apiV1}inscrit-tombola`, data
    );
  }
  findInscritById(id: number) {
    return this.http.get(`${environment.apiV1}request/findInscritById/${id}`)
  }
  updateInscrit(id: number, inscritInfo: any) {
    return this.http.patch(`${environment.apiV1}request/updateInscrit/${id}`, inscritInfo)
  }

  getPaginateInscrit():Observable<Pagination<IInscriptionTombola>>{
    return this.http.get<Pagination<IInscriptionTombola>>(`${environment.apiV1}request/paginateInscrit`)
  }

  searchInscrit(searchInscritTerm:string):Observable<Pagination<IInscriptionTombola>>{
    return this.http.get<Pagination<IInscriptionTombola>>( `${environment.apiV1}request/paginateInscrit?searchInscritTerm=${searchInscritTerm}`)
  }

  funcPaginate(
    link?: string,
    page?: number,
    search?: string
  ): Observable<Pagination<IInscriptionTombola>> {
    if (page && search == undefined) {
      link = `${environment.apiV1}request/paginateInscrit?page=${page}`;
      return this.http.get<Pagination<IInscriptionTombola>>(link)
    }
    if (page && search) {
      link = `${environment.apiV1}request/paginateInscrit?searchInscritTerm=${search}&page=${page}`;
      return this.http.get<Pagination<IInscriptionTombola>>(link)
    }
    if (search) {
      return this.http.get<Pagination<IInscriptionTombola>>(`${link}&searchInscritTerm=${search}`);
    }
    return this.http.get<Pagination<IInscriptionTombola>>(link);
  }
  printCodeInscrit(id: number){
    return this.http.get(`${environment.apiV1}request/printCodeInscrit/${id}`, {
      responseType: 'blob',
    })
  }

  downloadTarifs(){
    return this.http.get(`${environment.apiV1}inscrit-tombola/downloadTarifs`, {
      responseType: 'blob',
    })
  }
  downloadOffre(){
    return this.http.get(`${environment.apiV1}condidatures/downloadOffre`, {
      responseType: 'blob',
    })
  }
  exportInscritTombola(){
    return this.http.get(`${environment.apiV1}request/exportInscritTombola`, {
      responseType: 'blob',
    })
  }
}
