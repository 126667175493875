<nav
    class="relative flex flex-wrap items-center justify-between px-2  mb-2 bg-red-600 shadow-lg shadow-gray-200/50 fixed">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div class="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
            <a class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                href="#pablo">
                <img src="../../assets/images/logo.png" alt="" class=" h-7 w-35 mr-2">
            </a>

        </div>

    </div>
</nav>

<div class="bg-gray-50">
    <div class="text-lg lg:text-3xl text-center mx-auto font-semibold tracking-tight">
        Tentez votre chance à la tombola.
    </div>


    <div class="p-2 bg-white rounded mt-5 ">
        <form [formGroup]="inscriptionForm">

            <div class="flex-none md:flex w-full mb-2">
                <label for="nom" class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">Nom<span
                        class="text-red-500 text-lg">*</span>:</label>
                <div class="sm:w-6/12 w-full ">
                    <input type="text" maxlength="50" id="nom" class="w-full shadow-sm bg-white border border-gray-500 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 
                    dark:bg-gray-700 dark:border-gray-600 placeholder-red-100 dark:placeholder-gray-100 
                    dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Nom de famille" formControlName="nom">
                    <div *ngIf="inscriptionForm.controls['nom'].invalid && inscriptionForm.controls['nom'].touched">
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['nom'].errors['required']">Nom requis</span>
                        <span class="text-xs text-red-600" *ngIf="inscriptionForm.controls['nom'].errors['pattern']">Le
                            nom ne doit pas contenir 2 espaces consécutifs ou des chiffres</span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['nom'].errors['minlength']">Longueur minimale 3</span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['nom'].errors['maxlength']">Longueur maximale 25</span>
                    </div>
                </div>
            </div>

            <div class="flex-none md:flex w-full mb-2">
                <label for="prenom" class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">Prénom<span
                        class="text-red-500 text-lg">*</span>:</label>
                <div class="sm:w-6/12 w-full ">
                    <input type="text" maxlength="50" id="prenom" class=" w-full shadow-sm bg-white border border-gray-500 text-gray-900  rounded-lg focus:ring-blue-500
                     focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Prénom" formControlName="prenom">
                    <div
                        *ngIf="inscriptionForm.controls['prenom'].invalid && inscriptionForm.controls['prenom'].touched">
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['prenom'].errors['required']">Prénom requis</span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['prenom'].errors['pattern']">Le
                            Prénom ne doit pas contenir 2 espaces consécutifs ou des chiffres</span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['prenom'].errors['minlength']">Longueur minimale 3</span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['prenom'].errors['maxlength']">Longueur maximale 25</span>
                    </div>
                </div>
            </div>

            <div class="flex-none md:flex w-full mb-2">
                <label for="email" class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">Email<span
                        class="text-red-500 text-lg">*</span>:</label>
                <div class="sm:w-6/12 w-full ">
                    <input type="text" maxlength="100" id="email" class=" w-full shadow-sm bg-white border border-gray-500 text-gray-900  rounded-lg focus:ring-blue-500
                     focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Email@" formControlName="email">
                    <div *ngIf="inscriptionForm.controls['email'].invalid && inscriptionForm.controls['email'].touched">
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['email'].errors['required']">Email
                            requis</span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['email'].errors['pattern']">Email
                            incorrecte </span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['email'].errors['minlength']">Longueur
                            minimale 10 </span>
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['email'].errors['maxlength']">Longueur
                            maximale 100 </span>

                    </div>
                </div>
            </div>

            <div class="flex-none md:flex w-full mb-2">
                <label for="numTelephone" class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">Numéro
                    téléphone<span class="text-red-500 text-lg">*</span>:</label>
                <div class="sm:w-6/12 w-full ">
                    <input type="text" maxlength="10" id="numTelephone" class=" w-full shadow-sm bg-white border border-gray-500 text-gray-900  rounded-lg focus:ring-blue-500
                             focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                             dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Numéro téléphone" formControlName="numTelephone" mask="0000000000">
                    <div
                        *ngIf="inscriptionForm.controls['numTelephone'].invalid && inscriptionForm.controls['numTelephone'].touched">
                        <span class="text-xs text-red-600"
                            *ngIf="inscriptionForm.controls['numTelephone'].errors['pattern']">Numéro
                            incorrect</span>

                    </div>
                </div>
            </div>

            <div class="flex-none">
                <div class="flex-none md:flex w-full mb-2">
                    <label class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">
                        Avez-vous déjà utilisé le service Yalidine<span class="text-red-500 text-lg"></span>?</label>
                    <div class="sm:w-5/12 w-full flex mt-2">
                        <div class="form-check">
                            <label class="form-check-label border py-2 px-1" for="flexRadioDefault1">
                                <input class="form-check-input" type="radio" name="question1" id="flexRadioDefault1"
                                    formControlName="question1" [value]=true (click)="OnChangeQuestion1(true)">
                                Oui
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border ml-2  py-2 px-1" for="flexRadioDefault2">
                                <input class="form-check-input" type="radio" name="question1" id="flexRadioDefault2"
                                    formControlName="question1" [value]=false (click)="OnChangeQuestion1(false)">
                                Non
                            </label>
                        </div>
                    </div>
                </div>

                <div class="flex-none md:flex w-full mb-2 mt-3"*ngIf="inscriptionForm.get('question1').value">
                    <label for="boutique" class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">Nom boutique<span
                            class="text-red-500 text-lg"></span>:</label>
                    <div class="sm:w-6/12 w-full ">
                        <input type="text" maxlength="50" id="boutique" class="w-full shadow-sm bg-white border border-gray-500 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 
                        dark:bg-gray-700 dark:border-gray-600 placeholder-red-100 dark:placeholder-gray-100 
                        dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                            placeholder="Boutique" formControlName="boutique">
                        <!-- <div *ngIf="inscriptionForm.controls['boutique'].invalid && inscriptionForm.controls['boutique'].touched">
                            <span class="text-xs text-red-600"
                                *ngIf="inscriptionForm.controls['boutique'].errors['required']">boutique requis</span>
                            <span class="text-xs text-red-600" *ngIf="inscriptionForm.controls['boutique'].errors['pattern']">Le
                                boutique ne doit pas contenir 2 espaces consécutifs ou des chiffres</span>
                            <span class="text-xs text-red-600"
                                *ngIf="inscriptionForm.controls['boutique'].errors['minlength']">Longueur minimale 3</span>
                            <span class="text-xs text-red-600"
                                *ngIf="inscriptionForm.controls['boutique'].errors['maxlength']">Longueur maximale 25</span>
                        </div> -->
                    </div>
                </div>

                <div class="flex-none md:flex w-full mb-2" *ngIf="inscriptionForm.get('question1').value">
                    <label class="w-3/12 font-normal text-sm text-gray-900 dark:text-white">
                        Comment évaluez-vous votre expérience avec les services de Yalidine<span class="text-red-500 text-lg"></span>?
                    </label>
                    <div class="sm:w-9/12 w-full flex flex-wrap  gap-x-2 gap-y-3 mt-2">
                        <div class="form-check"> <!-- Pas besoin de flex-1 ici -->
                            <label class="form-check-label border py-1 md:py-2 px-1" for="flexRadioDefault3">
                                <input class="form-check-input" type="radio" name="question2" id="flexRadioDefault3" formControlName="question2" value="Excellent">
                                Excellent
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border py-1 md:py-2 px-1" for="flexRadioDefault4">
                                <input class="form-check-input" type="radio" name="question2" id="flexRadioDefault4" formControlName="question2" value="Bon">
                                Bon
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border py-1 md:py-2 px-1" for="flexRadioDefault5">
                                <input class="form-check-input" type="radio" name="question2" id="flexRadioDefault5" formControlName="question2" value="Moyen">
                                Moyen
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border py-1 md:py-2 px-1" for="flexRadioDefault6">
                                <input class="form-check-input" type="radio" name="question2" id="flexRadioDefault6" formControlName="question2" value="Insatisfaisant">
                                Insatisfaisant
                            </label>
                        </div>
                    </div>
                </div>
                
                <div class="flex-none md:flex w-full mb-2" *ngIf="inscriptionForm.get('question1').value">
                    <label class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">
                        Quelle est la chose que vous appréciez le plus dans les services de Yalidine<span
                            class="text-red-500 text-lg"></span>?</label>
                            <div class="sm:w-9/12 w-full flex flex-wrap  gap-x-2 gap-y-3 mt-2">
                   
                            <div class="form-check">
                                <label class="form-check-label border py-1 pl-1  pr-[17px]" for="flexRadioDefault7">
                                    <input class="form-check-input" type="radio" name="question3" id="flexRadioDefault7"
                                        formControlName="question3" value='Précision des délais'>
                                    Précision des délais
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label border   py-1 md:py-2 px-1" for="flexRadioDefault8">
                                    <input class="form-check-input" type="radio" name="question3" id="flexRadioDefault8"
                                        formControlName="question3" value='Qualité du service client'>
                                    Qualité du service client
                                </label>
                            </div>
                     
                            <div class="form-check">
                                <label class="form-check-label border   py-1 md:py-2 px-1" for="flexRadioDefault9">
                                    <input class="form-check-input" type="radio" name="question3" id="flexRadioDefault9"
                                        formControlName="question3" value='Diversité des services'>
                                    Diversité des services
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label border   py-1 md:py-2 px-1" for="flexRadioDefault10">
                                    <input class="form-check-input" type="radio" name="question3"
                                        id="flexRadioDefault10" formControlName="question3" value='Autre'>
                                    Autre
                                </label>
                            </div>
                    
                    </div>
                </div>

                <div class="flex-none md:flex w-full mb-2" *ngIf="inscriptionForm.get('question1').value">
                    <label class="w-3/12  font-normal text-sm text-gray-900 dark:text-white">
                        Quelle est la facilité d&#39;utilisation de l&#39;application Yaldine<span
                            class="text-red-500 text-lg"></span>?</label>

                            <div class="sm:w-9/12 w-full flex flex-wrap  gap-x-2 gap-y-3 mt-2">
                        <div class="form-check">
                            <label class="form-check-label border py-1 md:py-2 px-1" for="flexRadioDefault11">
                                <input class="form-check-input" type="radio" name="question4" id="flexRadioDefault11"
                                    formControlName="question4" value='Excellent'>
                                Excellent
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border   py-1 md:py-2 px-1" for="flexRadioDefault12">
                                <input class="form-check-input" type="radio" name="question4" id="flexRadioDefault12"
                                    formControlName="question4" value='Bon'>
                                Bon
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border   py-1 md:py-2 px-1" for="flexRadioDefault13">
                                <input class="form-check-input" type="radio" name="question4" id="flexRadioDefault13"
                                    formControlName="question4" value='Moyen'>
                                Moyen
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label border   py-1 md:py-2 px-1" for="flexRadioDefault14">
                                <input class="form-check-input" type="radio" name="question4" id="flexRadioDefault14"
                                    formControlName="question4" value='Insatisfaisant'>
                                Insatisfaisant
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto text-center my-3">
                <button (click)="InscrireToTombola()" type="button" [ngClass]="{'disabled':inscriptionForm.invalid,
                    'bg-red-600 hover:bg-red-700': !inscriptionForm.invalid}" class="  bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 
                     rounded inline-flex items-center" *ngIf="!btnSpinner">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 mr-1 -mt-1">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                    </svg>
                    INSCRIRE
                </button>
                <button role="status" type="button"  class="  text-white font-bold py-2 px-4 
                         rounded inline-flex items-center disabled" *ngIf="btnSpinner">
                         <svg aria-hidden="true" class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span >En cours...</span>
                    
                </button>



          
            </div>
        </form>

    </div>
</div>