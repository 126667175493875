import { Component } from '@angular/core';
import { SweetAlertService } from 'src/app/core/services/sweet-alert.service';
import { CandidatureService } from '../commercial/candidature/candidature.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-new-candidature',
  templateUrl: './new-candidature.component.html',
  styleUrls: ['./new-candidature.component.scss']
})
export class NewCandidatureComponent {
  selectedFile: any = [];
  spinnerAwait:boolean = false
  filesToUpload = {
    cv: null,
  }
  files1 = [{ name: 'Curriculum Vitae (CV)', column: 'cv' }]
  checkedFileForm = this.formBuilder.group({
    checkedFiles: [,]
  })

  constructor(
    private sweetAlertService: SweetAlertService,
    private candidatureService: CandidatureService,
    private formBuilder: FormBuilder,
  ) { }


  onSelect(event) {
    console.log('hhhhhhhhhhhhhhh', event.target.files[0].type)

    if (event.target.files[0].type === "application/pdf") {
      console.log(234567876543)
      this.filesToUpload['cv'] = event.target.files[0];
    } else if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
      this.filesToUpload['cv'] = event.target.files[0];
    } else {
      this.sweetAlertService.basicWarning(`Type de fichier non pris en charge`)
    }


  }

  checkfilesToUpload() {
    return this._checkfilesToUpload()
  }

  private _checkfilesToUpload(): boolean {
    for (const key of Object.keys(this.filesToUpload)) {
      if (this.filesToUpload['cv'] != null) {
        return true
      }
    }
    return false
  }

  async upload() {
    this.spinnerAwait =true
    for (const file in this.filesToUpload) {
      if (this.filesToUpload[file] != null) {
        this.filesToUpload[file]['fileType'] = file
        this.selectedFile.push(this.filesToUpload[file]);
      }
    }
    let fd = new FormData();
    for await (const file of this.selectedFile) {
      console.log("🚀 ~ file: employee-file.component.ts:131 ~ EmployeeFileComponent ~ upload ~ file:",  file)
      fd.append(`${file['fileType']}`, file, file.name);
      console.log("🚀 ~ NewCandidatureComponent ~ forawait ~ fd:", fd)
      console.log("🚀 ~ NewCandidatureComponent ~ forawait ~ file.name:", file.name)
      console.log("🚀 ~ NewCandidatureComponent ~ forawait ~ file['fileType']:", file['fileType'])

    }
    console.log("ssssssssssssssssssssssssssss", fd)
    this.candidatureService.uploadFiles( fd).subscribe({
      next:(response) => {
        console.log('succcessssssssssssssssssss');
        this.spinnerAwait =false
        this.sweetAlertService.creationSucces('Document envoyé avec succés')
   
        console.log("🚀 ~ file: employee-file.component.ts:141 ~ EmployeeFileComponent ~ upload ~ response:", response)
        this.selectedFile = []
        for (const key of Object.keys(this.filesToUpload)) {
          this.filesToUpload[key] = null;
        }
      },
      error:(error) => {
        console.log(error)
        this.selectedFile = []
        this.sweetAlertService.creationFailure(error.message)


      }

    });
  }
  confirmUpload() {
    const alertTitle = "Confirmation";
    const alertMessage = "voulez vous confirmer ce fichier ?";
    this.sweetAlertService
      .confirmStandard(alertTitle, alertMessage, '', '', null)
      .then((result) => {
        if (result.isConfirmed) {
          this.upload();
        }
      });

  }


}
