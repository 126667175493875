import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SweetAlertService } from 'src/app/core/services/sweet-alert.service';
import { TombolaService } from '../commercial/tombola/tombola.service';

@Component({
  selector: 'app-tombola-inscription',
  templateUrl: './tombola-inscription.component.html',
  styleUrls: ['./tombola-inscription.component.scss']
})
export class TombolaInscriptionComponent {
  btnSpinner: boolean = false;

  emailPattern =
    '^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$';
  namePattern = /^(?!.*\d)(?!.*  )[A-Za-z\s]+$/
  inscriptionForm = this.formBuilder.group({

    nom: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(this.namePattern)
      ]),
    ],
    prenom: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(this.namePattern)

      ]),
    ],

    email: [
      ,
      Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(100),
        Validators.pattern(this.emailPattern),
      ]),
    ],
    numTelephone: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^(05|06|07)\d{8}$/)

      ]),
    ],
    boutique: [
      '',
      Validators.compose([
      ]),],
    question1: [
      false,
      Validators.compose([
        Validators.required,
      ]),
    ],
    question2: [
      null,
      Validators.compose([
      ]),
    ],
    question3: [
      null,
      Validators.compose([
      ]),
    ],
    question4: [
      null,
      Validators.compose([

      ]),
    ],

  });

  constructor(
    private formBuilder: FormBuilder,
    private sweetAlertService: SweetAlertService,
    private tombolaService: TombolaService,

  ) { 
       

  }


  InscrireToTombola() {
    const alertTitle = 'Confirmation';
    const alertMessage = "Vous confirmez vos informations?";
    this.sweetAlertService
      .confirmStandard(alertTitle, alertMessage, '', '', null)
      .then((result) => {
        if (result.isConfirmed) {
      this.btnSpinner = true;
       
          this.tombolaService.InscrireUserToTombola(this.inscriptionForm.value).subscribe(
            (response) => {
              this.sweetAlertService.modificationSucces('Inscription réussie, veuillez créer un compte');
              this.inscriptionForm.reset()
              this.inscriptionForm.get('question1').setValue(false)
              this.inscriptionForm.get('question1').updateValueAndValidity();
          this.btnSpinner = false;


            },
            (error) => {
              console.log("🚀 ~ TombolaInscriptionComponent ~ .then ~ error:", error)
              this.sweetAlertService.modificationFailure('Verifiez vos information!');
          this.btnSpinner = false;

            }
          );
        }
      });
  }



  ResetForm() { }

  OnChangeQuestion1(value: boolean) {
    if (value == true) {
      this.inscriptionForm.get('question2').setValidators(
        [
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ]
      )
      this.inscriptionForm.get('question2').updateValueAndValidity();

      this.inscriptionForm.get('question3').setValidators(
        [
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ]
      )
      this.inscriptionForm.get('question3').updateValueAndValidity();

      this.inscriptionForm.get('question4').setValidators(
        [
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ]
      )
      this.inscriptionForm.get('question4').updateValueAndValidity();
    }
    else {

      this.inscriptionForm.get('question2').setValidators(null)
      this.inscriptionForm.get('question2').updateValueAndValidity();

      this.inscriptionForm.get('question3').setValidators(null)
      this.inscriptionForm.get('question3').updateValueAndValidity();

      this.inscriptionForm.get('question4').setValidators(null)
      this.inscriptionForm.get('question4').updateValueAndValidity();
    }
  }
}
