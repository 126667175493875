<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->

<fuse-vertical-navigation class="dark bg-white-900 print:hidden " [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex  dark items-center w-full bg-red-800">
            <!-- Logo -->
            <div class="flex  justify-start mx-4 mt-2 mb-2 hover:cursor-pointer" routerLink='accueil'>
                <!-- <span class=" ml-2 text-4xl font-bold">
                    COMMERCIAL
                </span> -->
                <img class="w-full" src="assets/images/logo.png">
            </div>
        </div>
        <!-- User -->
        <!-- <div class="flex flex-col items-center w-full p-4">
            <div class="relative ">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user?.image"
                    [src]="user?.image"
                    alt="User image">
                <mat-icon
                    class="icon-size-18"
                    *ngIf="!user?.image"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full ">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.email}}
                </div>
            </div>
        </div> -->
    </ng-container>

</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 ">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">

        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <settings></settings>
            <!-- <languages></languages> -->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <user></user>
            <!-- Button -->
            <button mat-icon-button (click)="goHome()" title="Déconecter">
                <span class="relative" >

                    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-end-on-rectangle'"></mat-icon>

                </span>
            </button>
      

        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->