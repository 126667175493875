import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SweetAlertService } from '../services/sweet-alert.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
    spinner: boolean = false;

    submitted = false;
    error = '';
    returnUrl: string = '';
    type: string;
  
    // set the currenr year
    // year: number = new Date().getFullYear();
  
    /**
     * Creates an instance of auth component.
     * @param authservice
     * @param router
     * @param alertConfig
     */
    constructor(
      private router: Router, 
      private authService :AuthService,
      private formBuilder:FormBuilder,
      private sweetAlertService: SweetAlertService,
      _route: ActivatedRoute,
      private cdRef: ChangeDetectorRef
      ) { 
        if (this.authService.isLoggedIn) {
          this.authService.redirectLoggedUser(this.authService.currentUser);
        } else{
          this.authService.logout()
        }
    }

    authForm  = this.formBuilder.group({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required])
      })

    ngOnInit() {
   
    }
    get f() {
      return this.authForm.controls;
    }
  
    /**
     * Determines whether login on
     */
    async onLogin() {
      if (this.authForm.invalid) {
        this.submitted = false;
        return;
      } else {
        this.submitted = true;
        this.spinner = true;
        (
          await this.authService.login(
            this.f.email.value,
            this.f.password.value,
            false
          )  
        ).subscribe({
          next: async (user: any) => {
            this.authService.redirectLoggedUser(this.authService.currentUser);
            this.spinner = false;
          },
          error:async (error) => {
            if ((await error.status) === 401) {
              this.type = 'danger';
              this.error = 'Email ou mot de passe incorrect';
              this.sweetAlertService.basicWarning('Informations incorrectes')
            }
            if ((await error.status) == undefined) {
              this.type = 'warning';
              this.error = 'Verifier Votre connexion';
              this.sweetAlertService.basicWarning('Verifier Votre connexion')

            }
            this.spinner = false;
          }
        });
      }
    }
  }


