<div *ngIf="response">
    <nav
    class="relative flex flex-wrap items-center justify-between px-2  mb-2 bg-red-600 shadow-lg shadow-gray-200/50 fixed">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div class="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
            <a class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                href="#pablo">
                <img src="../../assets/images/logo.png" alt="" class=" h-7 w-35 mr-2">
            </a>

        </div>

    </div>
</nav>

<div class="text-center mx-auto mt-3">
<h3 class="text-lg font-normal tracking-tight">En cas de problème click ici</h3>
</div>

<div class="text-center mx-auto mt-5">
    <button (click)="downloadOffre()" type="button"  class="  text-blue-600 hover:text-blue-700 font-bold py-2 px-4 
 rounded inline-flex items-center">
 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
  stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
  </svg>  
    Re-télécharger
</button>

</div>

</div>