import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';

import { NotfoundComponent } from './modules/notfound/notfound.component';
import { AuthComponent } from './modules/auth/auth.component';
import { LocalStoreManager } from './core/services/local-store-manager.service';
import { JWTTokenService } from './core/services/jwt.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './core/services/interceptor.service';
import { JwtInterceptor } from './modules/auth/jwt.interceptors';
import { ErrorsInterceptor } from './modules/auth/errors.interceptor';
import { FuseModule } from 'src/@fuse';
import { FuseConfigModule } from 'src/@fuse/services/config/config.module';
import { AppComponent } from './app.component';
import { appConfig } from './core/config/app.config';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { appRoutes } from './app.routing';
import { TombolaInscriptionComponent } from './modules/tombola-inscription/tombola-inscription.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { OffreComponent } from './modules/offre/offre.component';
import { NewCandidatureComponent } from './modules/new-candidature/new-candidature.component';




const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        NotfoundComponent, 
        AuthComponent,
        TombolaInscriptionComponent,
        OffreComponent, 
        NewCandidatureComponent

  
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        // MarkdownModule.forRoot({}),
        SharedModule,
        // FormsModule,
        // ReactiveFormsModule,
        NgxMaskDirective, NgxMaskPipe
    ],
    providers:[
      provideNgxMask(),
        { provide: HTTP_INTERCEPTORS,
          useClass: ErrorsInterceptor,
          multi: true
        },
        { provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
        },
        { provide: HTTP_INTERCEPTORS,
          useClass: InterceptorService,
          multi: true
        },
        JWTTokenService,
        LocalStoreManager
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
